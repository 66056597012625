import { PersonalisationGallery } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Product'

import { SelectedOptions, TransformedOption } from '../transformers/option'
import {
  ChoiceSelectedVariantMap,
  ProductOptionsMap,
  UNSELECTED_KEY,
} from '../transformers/productOptionsMap'
import { TransformedVariant } from '../transformers/variant'
import { VariantOptions } from '../types'

// @TODO: REBUILD-8946: traverse the product options map more efficiently
export const findProductOptions = (
  sortedOptionKeys: string[],
  selectedOptions: { [optionKey: string]: string },
  productOptionsMap,
) => {
  const { productOptions } = sortedOptionKeys.reduce<{
    productOptions: VariantOptions | null
    currentMap: VariantOptions | ChoiceSelectedVariantMap | null
  }>(
    (accumulator, optionKey) => {
      if (!accumulator.currentMap) {
        const currentMapValue = productOptionsMap[selectedOptions[optionKey]]

        accumulator.currentMap = currentMapValue
        if (currentMapValue?.options) {
          accumulator.productOptions = currentMapValue
        }
        return accumulator
      }

      const currentMapValue = accumulator.currentMap[selectedOptions[optionKey]]

      if (accumulator.currentMap && currentMapValue?.options) {
        accumulator.productOptions = currentMapValue
        return accumulator
      }

      accumulator.currentMap = currentMapValue

      return accumulator
    },
    {
      productOptions: null,
      currentMap: null,
    },
  )

  return productOptions
}

// @TODO: REBUILD-8946: this logic can be removed with selectedChoices rework
export const getSelectedOptions = (
  sortedOptionKeys: string[] | null,
  defaultSelectedChoices: string[] | null,
) => {
  const selectedOptions = sortedOptionKeys
    ? sortedOptionKeys.reduce<{
        [optionKey: string]: string
      }>((accumulator, optionKey, index) => {
        if (
          defaultSelectedChoices &&
          defaultSelectedChoices.length === sortedOptionKeys?.length
        ) {
          accumulator[optionKey] = defaultSelectedChoices[index]
        }
        return accumulator
      }, {})
    : null

  return selectedOptions
}

const findProductOptionsFromChoices = ({
  selectedOptions,
  productOptionsMap,
}: {
  selectedOptions: { [optionKey: string]: string }
  productOptionsMap: ProductOptionsMap
}) => {
  const { productOptions } = Object.values(selectedOptions).reduce<{
    productOptions: VariantOptions | null
    currentMap: VariantOptions | ChoiceSelectedVariantMap | null
  }>(
    (accumulator, choiceKey) => {
      if (!productOptionsMap) {
        return accumulator
      }

      if (!accumulator.currentMap) {
        const currentMapValue = productOptionsMap[choiceKey]

        accumulator.currentMap = currentMapValue
        if (currentMapValue?.options) {
          accumulator.productOptions = currentMapValue
        }
        return accumulator
      }

      const currentMapValue = accumulator.currentMap[choiceKey]

      if (accumulator.currentMap && currentMapValue?.options) {
        accumulator.productOptions = currentMapValue
        return accumulator
      }

      accumulator.currentMap = currentMapValue

      return accumulator
    },
    {
      productOptions: null,
      currentMap: null,
    },
  )

  return productOptions
}

enum PersonalisedImageTypes {
  THUMBNAIL = 'THUMBNAIL',
  ORIGINAL = 'ORIGINAL',
  MAGNIFY = 'MAGNIFY',
}

export const personalisationImagesTransformer = (selectedVariant) => {
  const personalisationImages =
    selectedVariant.personalisationData?.personalisationSupportImages
  if (!personalisationImages) {
    return
  }
  const transformedPersonalisationImages: PersonalisationGallery[] = []
  personalisationImages.forEach((item) => {
    item.supportImages?.imagesWithAssetSets?.forEach((imageSet) => {
      const assetSet: string = imageSet.assetSet
      const images = imageSet.images

      const thumbnails = {
        url: images.find(
          (image) => image.size === PersonalisedImageTypes.THUMBNAIL,
        ).url,
        alt: selectedVariant?.title || '',
      }

      const originals = {
        url: images.find(
          (image) => image.size === PersonalisedImageTypes.ORIGINAL,
        ).url,
        alt: selectedVariant?.title || '',
      }

      const magnifies = {
        url: images.find(
          (image) => image.size === PersonalisedImageTypes.MAGNIFY,
        ).url,
        alt: selectedVariant?.title || '',
      }

      const selectedGallery = transformedPersonalisationImages.find(
        (gallery) => gallery.key === assetSet,
      )
      let index = selectedGallery
        ? transformedPersonalisationImages.indexOf(selectedGallery)
        : 0

      if (!selectedGallery) {
        transformedPersonalisationImages.push({
          key: assetSet,
          images: [],
          thumbnails: [],
          zoom: [],
        })
        index = transformedPersonalisationImages.length - 1
      }
      transformedPersonalisationImages[index]?.images.push(originals)
      transformedPersonalisationImages[index]?.thumbnails.push(thumbnails)
      transformedPersonalisationImages[index]?.zoom.push(magnifies)
    })
  })
  return transformedPersonalisationImages
}

export const getProductOptionsFromChoices = ({
  productOptionsMap,
  selectedOptions,
  setSelectedOptionsCallback,
  setOptionsCallback,
  setSelectedVariantCallback,
  selectedVariant,
  optionKey,
  value,
}: {
  productOptionsMap: ProductOptionsMap
  optionKey: string
  value: string
  selectedOptions: SelectedOptions
  setSelectedOptionsCallback: (selectedOptions: SelectedOptions) => void
  setOptionsCallback: (options: TransformedOption[]) => void
  setSelectedVariantCallback: (selectedVariant: TransformedVariant) => void
  selectedVariant?: TransformedVariant
}) => {
  const updatedSelectedOptions = selectedOptions

  updatedSelectedOptions[optionKey] = value
  setSelectedOptionsCallback(updatedSelectedOptions)

  const updatedProductOptions = findProductOptionsFromChoices({
    selectedOptions,
    productOptionsMap,
  })

  if (updatedProductOptions) {
    setOptionsCallback(updatedProductOptions.options)
    setSelectedVariantCallback(updatedProductOptions.variant)

    return { updatedSelectedOptions, updatedProductOptions }
  }

  if (selectedVariant?.product?.linkedOn) {
    const fallbackSelectedOptions = Object.entries(
      updatedSelectedOptions,
    ).reduce((accumulator, [optionKey, choiceKey]) => {
      if (optionKey === selectedVariant.product?.linkedOn) {
        accumulator[optionKey] = choiceKey
      } else {
        accumulator[optionKey] = UNSELECTED_KEY
      }
      return accumulator
    }, {})

    const fallbackProductOptions = findProductOptionsFromChoices({
      productOptionsMap,
      selectedOptions: fallbackSelectedOptions,
    })

    if (fallbackProductOptions) {
      setOptionsCallback(fallbackProductOptions.options)
      setSelectedVariantCallback(fallbackProductOptions.variant)

      return {
        updatedSelectedOptions: fallbackSelectedOptions,
        updatedProductOptions: fallbackProductOptions,
      }
    }
  }

  return { updatedSelectedOptions: null, updatedProductOptions: null }
}
