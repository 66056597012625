import * as React from 'react'
import { useMutation } from '@apollo/react-hooks'
import axios from 'axios'
import { vsprintf } from 'sprintf-js'

import { QuickBuyStockNotification } from '@thg-commerce/enterprise-components'
import { QuickBuyModal } from '@thg-commerce/enterprise-components/QuickBuyModal/AuroraQuickBuyModal'
import { VariantFields } from '@thg-commerce/enterprise-components/src/transformer/productOptionsTransformer'
import {
  EnterpriseContext,
  i18n,
  // useFormattableI18nProperty, @TODO: REBUILD-9022
  useLogger,
  useSiteConfig,
  useSiteDefinition,
  useTheme as useCoreTheme,
  useToastPresenter,
  useTransmit,
} from '@thg-commerce/enterprise-core'
import { useBackendEventNormaliser } from '@thg-commerce/enterprise-metrics'
import {
  Country,
  Currency,
  // Feature, @TODO: REBUILD-9022
  FulfilmentMethod,
  MutationAddProductToBasketWithSubscriptionContractArgs,
  // Scalars, @TODO: REBUILD-9022
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { Beacon } from '@thg-commerce/enterprise-network/src/transformers/sponsoredAds/products'
import { SelectedOptions } from '@thg-commerce/enterprise-product-options/src'
import { TransformedOption } from '@thg-commerce/enterprise-product-options/src/transformers/option'
import {
  ProductOptionsMap,
  UNSELECTED_KEY,
} from '@thg-commerce/enterprise-product-options/src/transformers/productOptionsMap'
import { getProductOptionsFromChoices } from '@thg-commerce/enterprise-product-options/src/utils/options'
import { getPrice } from '@thg-commerce/enterprise-product-options/src/utils/price'
import { ToastBannerType } from '@thg-commerce/gravity-elements'
import { useTheme } from '@thg-commerce/gravity-patterns/theme'

import { AddProductToBasketWithSubscriptionContract as ADD_TO_BASKET_WITH_CONTRACT_MUTATION } from '../../../graphql/Mutation/AddProductToBasketWithSubscriptionContract.graphql'
import { useHeaderHeight } from '../../EnterpriseContext'
import { BasketContext } from '../BasketContext'
import { useAddToBasket } from '../hooks'
import { InteractionLocation } from '../hooks/useAddToBasket'
import { useBasket, useBasketId } from '../hooks/useBasketId'

import { quickBuyModalDataTrackingCallbacks } from './dataTracking'
// @TODO: REBUILD-9022
// import { SubscriptionChoice } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Product'
import { getMaxQuantity } from './utils'

const MAX_QUANTITY = 5000
const DEFAULT_TIMEOUT = 5000

type AddBasketWithContractType = {
  addProductToBasketWithSubscriptionContract: {
    id: string
  }
}

// const INVALID_CONTRACT_ID = '15'
export const QuickBuyModalPresenter = () => {
  const logger = useLogger()
  const {
    // productTagsKeys,
    // showSavingsAmountOnSubscriptionCard,
    enableMobilePDPStickyAddToBasket,
    enableQuickbuyOnlyOnMobile,
    // enableVipPrice,
    // auroraProductOptions,
    enablePreselectedProductVariant,
  } = useSiteConfig()

  const { originUrl, defaultCurrency, defaultLocale } = useSiteDefinition()
  const {
    currency,
    shippingDestination,
    extensionsRef,
    // horizonFeatures,
    // appConfig,
    currentLocation,
    config,
  } = React.useContext(EnterpriseContext)

  const headerHeight = useHeaderHeight()
  const basketContext = React.useContext(BasketContext)
  const transmit = useTransmit()
  const normaliseBackendEvent = useBackendEventNormaliser()
  const { basket } = useBasket()
  const theme = useTheme()
  const coreTheme = useCoreTheme()
  const [_, setBasketId] = useBasketId()

  const [open, setOpen] = React.useState(false)
  const [selectedVariant, setSelectedVariant] = React.useState<
    VariantFields | undefined
  >()
  const [options, setOptions] = React.useState<TransformedOption[]>([])
  const [selectedOptions, setSelectedOptions] = React.useState<SelectedOptions>(
    {},
  )
  const productOptionsMap = React.useRef<ProductOptionsMap>()

  const initialQuantity = React.useRef(1)
  const [showErrorMessage, setShowErrorMessage] = React.useState(false)

  const [addToBaskedWithContracts] = useMutation<
    {
      addProductToBasketWithSubscriptionContract: AddBasketWithContractType
    },
    MutationAddProductToBasketWithSubscriptionContractArgs
  >(ADD_TO_BASKET_WITH_CONTRACT_MUTATION, {
    fetchPolicy: 'no-cache',

    onCompleted: (data) => {
      if (data) {
        setBasketId(
          data.addProductToBasketWithSubscriptionContract
            .addProductToBasketWithSubscriptionContract.id,
        )
      }
    },
    onError: (error) => {
      logger.warn(
        `[AddToBasketWithContracts]: Failed to add to basket ${error.message}`,
      )
      setShowErrorMessage(true)
    },
  })

  const { execute: addToBasket } = useAddToBasket({
    errorHandler: () => setShowErrorMessage(true),
    forceAddToBasket: true,
  })

  const { maxQuantity, quantityInBasket } = getMaxQuantity(
    basket,
    selectedVariant,
  )

  const presentToast = useToastPresenter()

  // @TODO: REBUILD-9022: Add subscriptions to new quickbuy modal
  // const [activeTabIndex, setActiveTabIndex] = React.useState<number>(
  //   selectedVariant?.subscriptionPaymentType ===
  //     SubscriptionPaymentType.ON_DISPATCH &&
  //     data?.quickBuyModal.product.subscriptionChoices?.upfront.length
  //     ? 1
  //     : 0,
  // )
  //
  // const basketSubscriptionContract = basket?.items?.find(
  //   (item) => item.product.sku === selectedVariant?.sku,
  // )?.subscriptionContract
  //
  // const [selectedFrequencyId, setSelectedFrequencyId] = React.useState<string>(
  //   basketSubscriptionContract?.id ||
  //   lectedVariant?.subscriptionContracts?.[0]?.id ||
  //   VALID_CONTRACT_ID,
  // )

  const handleError = () => {
    presentToast({
      message: errorMessage,
      bannerType: ToastBannerType.DANGER,
    })
    setOpen(false)
    return null
  }

  const resetProductOptionsState = () => {
    setOptions([])
    setSelectedOptions({})
    productOptionsMap.current = undefined
    setSelectedVariant(undefined)
  }

  React.useEffect(() => {
    if (!open) {
      resetProductOptionsState()
      setShowErrorMessage(false)
    }
  }, [open])

  React.useEffect(() => {
    if (open) {
      const {
        value: [getExtensions],
      } = extensionsRef
      const extensions = getExtensions()
      transmit({
        type: 'page_visit',
        payload: normaliseBackendEvent({
          basket,
          eventData: {
            type: 'page_visit',
            subtype: 'quickbuy_modal_shown',
          },
          experiments: extensions?.experiments,
          requestData: {
            ...(extensions?.LoggerLinkData || {
              start_timestamp: Date.now(),
              duration_ms: 0,
            }),
            url: window.location.href,
          },
        }),
      })
    }
  }, [open, basket, extensionsRef, normaliseBackendEvent, transmit])

  const addToBaskedWithContractsItems = (
    currentQuantity: number,
    selectedContractId: string,
  ) => {
    addToBaskedWithContracts({
      variables: {
        basketId: basket?.id,
        sku: selectedVariant?.sku.toString(),
        quantity: currentQuantity,
        contractId: selectedContractId,
        settings: {
          currency: currency as Currency,
          shippingDestination: shippingDestination.code as Country,
        },
      },
    }).then(() => {
      if (!basketContext.presentAddedToBasketModal || !selectedVariant) {
        return
      }

      const currentAddedToBasketModalPresenter =
        basketContext.presentAddedToBasketModal.current

      setOpen(false)
      currentAddedToBasketModalPresenter(
        selectedVariant?.sku.toString(),
        currentQuantity,
      )
    })
  }

  const i18nText = {
    modalTitle: i18n('basket.quickbuy.title'),
    rrpLabel: i18n('general.rrp.text'),
    saveLabel: i18n('general.save.text'),
    addToBasket: i18n('basket.addproduct.text'),
    viewMoreInformation: i18n('basket.productinfolink.text'),
    missingProductMessage: i18n('general.product.quickbuy.missing.text'),
    offLabel: i18n('general.off.text'),
    swatch: {
      unavailableText: i18n('product.productoptions.dropdown.unavailable.text'),
      closeButtonText: i18n('product.productoptions.swatch.tooltip.close.text'),
      showMoreButtonText: i18n(
        'product.productoptions.swatch.button.more.text',
      ),
      showLessButtonText: i18n(
        'product.productoptions.swatch.button.less.text',
      ),
    },
    imageSwatch: {
      showButtonText: i18n(
        'product.productoptions.imageswatch.button.show.text',
      ),
      showMoreButtonText: i18n(
        'product.productoptions.imageswatch.button.more.text',
      ),
      showLessButtonText: i18n(
        'product.productoptions.imageswatch.button.less.text',
      ),
    },
    dropdown: {
      unavailableText: i18n('product.productoptions.dropdown.unavailable.text'),
      customLabelText: i18n('product.productoptions.dropdown.customlabel.text'),
    },
    wishlist: {
      unavailableText: i18n('account.wishlist.option.unavailable.text'),
    },
  }

  const unselectedVariant = Object.entries(selectedOptions).reduce(
    (accumulator, [optionKey, choiceKey]) => {
      if (accumulator) {
        return accumulator
      }

      if (optionKey !== selectedVariant?.product.linkedOn) {
        return choiceKey === UNSELECTED_KEY
      }

      return accumulator
    },
    false,
  )

  const errorMessage = i18n(
    unselectedVariant
      ? 'product.missing.size.error'
      : 'basket.addtobasket.error.text',
  )

  const modalI18nText = {
    closeAriaLabel: i18n('general.modal.close.button.arialabel'),
    closeLabel: i18n('general.modal.close.button.label'),
  }

  const quantitySelectorI18nText = {
    decreaseButtonLabel: i18n('general.quantity.decrease'),
    increaseButtonLabel: i18n('general.quantity.increase'),
    quantityLabel: i18n('general.quantity.text'),
  }

  const basketQuantity18nText = {
    limit: i18n('product.maxquantity.basket.limit.text'),
    singleItemText: i18n('product.maxquantity.basket.singleitem.text'),
    multipleItemsText: i18n('product.maxquantity.basket.multipleitems.text'),
  }

  // @TODO: REBUILD-9022: Add subscriptions to new quickbuy modal
  // const subscriptionInformation18nText = {
  //   productWithContractAlreadyInBasket: i18n(
  //     'product.inbasketwith.subscription.contracts',
  //   ),
  //   productWithoutContractAlreadyInBasket: i18n(
  //     'product.alreadyaddedinbasket.info.text',
  //   ),
  //   oneTimePurchaseDiscountText: useFormattableI18nProperty(
  //     'product.subscription.onetimepurchase.discount.percentage.text',
  //   ),
  // }

  const maxQuantityBasketItemMessageText = vsprintf(
    quantityInBasket > 1
      ? basketQuantity18nText.multipleItemsText
      : basketQuantity18nText.singleItemText,
    [selectedVariant?.maxPerOrder || MAX_QUANTITY, quantityInBasket],
  )

  const isFromRecommendations = React.useRef<boolean>(false)
  const displayViewMoreInfo = React.useRef<boolean>()
  const onBasketChangeBeaconRef = React.useRef<Beacon>()
  const trackingUrlRef = React.useRef<string>()
  const callbacks = React.useRef<{
    setSelectedOptionsCallback?: (selectedOptions: SelectedOptions) => void
    setOptionsCallback?: (options: TransformedOption[]) => void
    setSelectedVariantCallback?: (selectedVariant) => void
  }>()

  // @TODO: REBUILD-9022: Add subscriptions to new quickbuy modal
  // const hasHorizonSubscriptions =
  //   horizonFeatures?.includes(Feature.Subscriptions) || false
  // const hasHorizonSubscriptionContracts =
  //   horizonFeatures?.includes(Feature.SubscribeAndSave) || false
  //
  // const isSubscriptionPurchaseOptionsActive =
  //   hasHorizonSubscriptions || hasHorizonSubscriptionContracts

  basketContext.presentAuroraQuickBuyModal.current = ({
    sku,
    quantity,
    fromRecommendations: fromRecommendationsInput,
    onBasketChangeBeacon,
    trackingUrl,
    initialSelectedOptions,
    setSelectedOptionsCallback,
    setSelectedVariantCallback,
    setOptionsCallback,
    fullProductData,
    displayNotifyWhenInStock,
    enableMobilePDPStickyAddToBasket,
  }: {
    sku: string
    quantity: number
    fromRecommendations: boolean
    initialSelectedOptions: SelectedOptions
    onBasketChangeBeacon?: Beacon
    trackingUrl?: string
    setSelectedOptionsCallback?: (selectedOptions: SelectedOptions) => void
    setSelectedVariantCallback?: (selectedVariant) => void
    setOptionsCallback?: (options: TransformedOption[]) => void
    fullProductData?: boolean
    displayNotifyWhenInStock?: boolean
    enableMobilePDPStickyAddToBasket?: boolean
  }) => {
    const fromRecommendations = fromRecommendationsInput ?? false

    if (!initialSelectedOptions) {
      handleError()
      return
    }

    initialQuantity.current = quantity

    isFromRecommendations.current = fromRecommendations
    onBasketChangeBeaconRef.current = onBasketChangeBeacon
    trackingUrlRef.current = trackingUrl
    displayViewMoreInfo.current = enableMobilePDPStickyAddToBasket

    setSelectedOptions(initialSelectedOptions)

    callbacks.current = {
      setSelectedOptionsCallback,
      setSelectedVariantCallback,
      setOptionsCallback,
    }

    const auroraUrl = fullProductData
      ? `/e2/operation/product/product-options/${sku}`
      : `/e2/operation/product-options/${sku}?displayNotifyWhenInStock=${displayNotifyWhenInStock}`

    if (initialSelectedOptions) {
      axios
        .get(auroraUrl, {
          timeout: config?.PRODUCT_OPTIONS_TIMEOUT ?? DEFAULT_TIMEOUT,
          headers: {
            'Accept-Encoding': 'deflate, gzip, br',
            Host: originUrl,
            'x-customer-location': currentLocation,
          },
        })
        .then((response) => response.data)
        .then((responseData) => {
          const selectedProductOptions = Object.values(
            initialSelectedOptions,
          ).reduce((accumulator, choiceKey) => {
            return accumulator[choiceKey]
          }, responseData)

          if (!selectedProductOptions) {
            handleError()
            return
          }

          setSelectedVariant(selectedProductOptions.variant)
          setOptions(selectedProductOptions.options)
          productOptionsMap.current = responseData
        })
        .catch((e) => {
          console.trace(`Error fetching product options: ${auroraUrl}`, e)
          handleError()
        })
    }

    setOpen(true)
  }

  if (!basketContext.presentAuroraQuickBuyModal) {
    logger.warn(
      'AuroraQuickBuyModalPresenter: Failed to find an initialised BasketContext. Make sure presentAuroraQuickBuyModal ref is defined and it wraps AuroraQuickBuyModalPresenter.',
    )
    return null
  }

  if (!open) {
    return null
  }

  const addToBasketCallback = (
    _: { [option: string]: string },
    quantity: number,
  ) => {
    if (!basketContext.presentAddedToBasketModal) {
      return
    }

    if (!selectedVariant) {
      return
    }

    if (!selectedVariant.enableAddToBasket) {
      setShowErrorMessage(true)
      return
    }

    const currentAddedToBasketModalPresenter =
      basketContext.presentAddedToBasketModal.current
    basketContext.presentAddedToBasketModal.current = (
      sku: string,
      quantity: number,
    ) => {
      if (!basketContext.presentAddedToBasketModal) {
        return
      }

      setOpen(false)
      setShowErrorMessage(false)
      currentAddedToBasketModalPresenter(sku, quantity)

      basketContext.presentAddedToBasketModal.current = currentAddedToBasketModalPresenter
    }

    addToBasket(
      [{ quantity, sku: selectedVariant.sku.toString() }],
      {
        fromRecommendations: isFromRecommendations.current,
        location: InteractionLocation.PRODUCT_LIST,
      },
      FulfilmentMethod.HomeDelivery,
      undefined,
      onBasketChangeBeaconRef.current,
      trackingUrlRef.current,
    )
    quickBuyModalDataTrackingCallbacks.modalAddToBasket()
  }

  // @TODO: REBUILD-9022: Add subscriptions to new quickbuy modal
  // const productHasVariantsWithContracts =
  //   data?.quickBuyModal.product.variants.some(
  //     (variant) => variant.subscriptionContracts?.length,
  //   ) || false
  //
  // const hasContractsOrIsSubscription =
  //   selectedVariant?.isSubscription || productHasVariantsWithContracts
  //
  // const subscriptionChoices = data?.quickBuyModal.product.subscriptionChoices
  //
  // const hasSubscriptionWithChoices =
  //   hasHorizonSubscriptions &&
  //   !!(
  //     subscriptionChoices?.onDispatch?.length ||
  //     subscriptionChoices?.upfront.length
  //   )
  //
  // const isEmptySubscriptionBasket =
  //   hasHorizonSubscriptionContracts &&
  //   selectedVariant &&
  //   basket?.items?.length === 0
  //
  // const productInBasketWithSubscriptionContract =
  //   !!basketSubscriptionContract && activeTabIndex === 0
  //
  // const productInBasketWithoutSubscriptionContract =
  //   basketSubscriptionContract === null &&
  //   activeTabIndex === 1 &&
  //   !isEmptySubscriptionBasket

  // @TODO: INGBEA-931: Add subscriptions pricing fix to new quickbuy modal
  // const getSelectedContractPrice = (
  //     contracts: SubscriptionContract[] | undefined,
  //     selectedId: string,
  //     defaultPrice?: string,
  //   ) => {
  //     return (
  //       contracts?.find((contract) => contract.id === selectedId)?.initialPrice
  //         ?.price?.displayValue ||
  //       defaultPrice ||
  //       'N/A'
  //     )
  //   }

  //   const selectedContractDiscountedPrice = getSelectedContractPrice(
  //     selectedVariant?.subscriptionContracts,
  //     selectedFrequencyId,
  //     selectedVariant?.price?.price.displayValue,
  //   )

  // const price={getPrice(
  //   selectedVariant,
  //   defaultCurrency,
  //   defaultLocale,
  //   activeTabIndex,
  //   selectedContractDiscountedPrice,
  // )}

  const quickBuyModalTheme = theme.patterns.quickBuyModal

  // @TODO: REBUILD-9022: Add subscriptions to new quickbuy modal
  // const subscriptionInfoMessageText =
  //   (productInBasketWithSubscriptionContract
  //     ? subscriptionInformation18nText.productWithContractAlreadyInBasket
  //     : subscriptionInformation18nText?.productWithoutContractAlreadyInBasket) ||
  //   ''
  //
  // const selectedContractInitialDiscountPercentage = selectedVariant?.subscriptionContracts?.find(
  //   (contract) => contract.id === selectedFrequencyId,
  // )?.initialDiscountPercentage
  //
  // const selectedContractUpsellMessage = selectedVariant?.subscriptionContracts?.find(
  //   (contract) => contract.id === selectedFrequencyId,
  // )?.upsellMessage
  //
  // const getSelectedContractDiscountMessage = (
  //   activeTabIndex: number,
  //   initialDiscountPercentage: number,
  //   upsellMessage: string,
  // ) => {
  //   if (activeTabIndex === 0 && initialDiscountPercentage) {
  //     return subscriptionInformation18nText.oneTimePurchaseDiscountText(
  //       `${initialDiscountPercentage}%`,
  //     )
  //   }
  //   if (activeTabIndex === 1) {
  //     return upsellMessage
  //   }
  //   return null
  // }
  //
  // const subscriptionContractDiscountMessage = hasHorizonSubscriptions
  //   ? getSelectedContractDiscountMessage(
  //     activeTabIndex,
  //     selectedContractInitialDiscountPercentage || 0,
  //     selectedContractUpsellMessage,
  //   )
  //   : null
  //
  // const product = data &&
  //   selectedVariant && {
  //   sku: data.quickBuyModal.product.sku,
  //   image: {
  //     urls: {
  //       largeProduct: selectedVariant.images[0].largeProduct || '',
  //     },
  //     alt: selectedVariant.title,
  //   },
  //   title: selectedVariant.title,
  //   tags: data.quickBuyModal.product.tags,
  //   inStock: selectedVariant.inStock,
  //   notifyWhenInStockEnabled:
  //     selectedVariant?.notifyWhenInStockEnabled || false,
  //   subscriptionData: {
  //     productInBasketWithSubscriptionContract,
  //     productInBasketWithoutSubscriptionContract,
  //     selectedFrequencyId,
  //     activeTabIndex,
  //     enableSubscriptionContracts: hasHorizonSubscriptionContracts,
  //     subscriptionPurchaseOptions: {
  //       setActiveTabIndex,
  //       activeTabIndex,
  //       selectedFrequencyId,
  //       setSelectedFrequencyId,
  //       showSavingsAmountOnSubscriptionCard:
  //         showSavingsAmountOnSubscriptionCard || false,
  //       isSubscription: selectedVariant.isSubscription || false,
  //       subscribeOptions: subscriptionChoices,
  //       subscriptionPaymentType: selectedVariant.subscriptionPaymentType,
  //       subscriptionContracts: selectedVariant.subscriptionContracts || [],
  //       isQuickBuy: true,
  //       purchaseOptionsStyle: coreTheme.widget.productList.purchaseOptions,
  //     },
  //     displaySubscriptionChoicesOnly: hasSubscriptionWithChoices,
  //     displaySubscription:
  //       hasContractsOrIsSubscription && isSubscriptionPurchaseOptionsActive,
  //   },
  // }

  const selectedOptionsCallback = (optionKey, value) => {
    if (!productOptionsMap.current) {
      handleError()
      return
    }

    setShowErrorMessage(false)
    const {
      updatedSelectedOptions,
      updatedProductOptions,
    } = getProductOptionsFromChoices({
      selectedOptions,
      selectedVariant,
      optionKey,
      value,
      productOptionsMap: productOptionsMap.current,
      setSelectedOptionsCallback: setSelectedOptions,
      setSelectedVariantCallback: setSelectedVariant,
      setOptionsCallback: setOptions,
    })

    if (callbacks.current && updatedSelectedOptions && updatedProductOptions) {
      callbacks.current.setSelectedOptionsCallback &&
        callbacks.current.setSelectedOptionsCallback(updatedSelectedOptions)
      callbacks.current.setSelectedVariantCallback &&
        callbacks.current.setSelectedVariantCallback(
          updatedProductOptions.variant,
        )
      callbacks.current.setOptionsCallback &&
        callbacks.current.setOptionsCallback(updatedProductOptions.options)
    }
  }

  return (
    <QuickBuyModal
      enablePreselectedProductVariant={enablePreselectedProductVariant}
      loading={!options.length}
      setOpen={setOpen}
      options={options}
      product={selectedVariant}
      quickBuyModalTheme={quickBuyModalTheme}
      displayViewMoreInfo={Boolean(displayViewMoreInfo)}
      coreTheme={coreTheme}
      rendering={{
        open,
        onClose: () => {
          setOpen(false)
          quickBuyModalDataTrackingCallbacks.modalClose()
        },
      }}
      i18nText={{
        ...i18nText,
        maxQuantityBasketItemMessageText,
        subscriptionInfoMessageText: '', // @TODO: REBUILD-9022: subscription changes
        subscriptionContractDiscountMessage: '', // @TODO: REBUILD-9022: subscription changes,
        failedToAddToBasketError: showErrorMessage ? errorMessage : undefined,
        availabilityMessage: selectedVariant?.availabilityMessage,
      }}
      modal={{
        i18nText: modalI18nText,
        showHeader: true,
        stickyHeader: true,
        headerOffset: headerHeight,
        gridColSpan: [12, 8, 8, 6],
        'data-testid': 'quick-buy-modal',
        animation: theme.patterns.quickBuyModal.animation,
      }}
      selectedOptions={selectedOptions}
      setSelectedOptionsCallback={selectedOptionsCallback}
      pricing={
        selectedVariant
          ? getPrice(selectedVariant, defaultCurrency, defaultLocale)
          : undefined
      }
      links={{ productUrl: selectedVariant?.product.url }}
      callbacks={{
        addedToBasketClicked: addToBasketCallback,
        viewMoreInformationClicked: () => {
          selectedVariant &&
            quickBuyModalDataTrackingCallbacks.modalInformation(
              selectedVariant?.product.sku.toString(),
            )
        },
        addToBasketWithContractsCallback: addToBaskedWithContractsItems,
      }}
      quantitySelector={{
        i18nText: quantitySelectorI18nText,
        maxValue: maxQuantity,
        quantity: initialQuantity.current,
      }}
      {...(enableMobilePDPStickyAddToBasket &&
        enableQuickbuyOnlyOnMobile && {
          inStockComponent: (
            <QuickBuyStockNotification
              selectedVariant={selectedVariant}
              url={selectedVariant?.product.url || ''}
              styleOverride={{
                margin: { top: 0, bottom: 2 },
              }}
            />
          ),
        })}
    />
  )
}
