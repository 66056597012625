import * as React from 'react'
import ResizeObserver from 'resize-observer-polyfill'

import { SubscriptionPaymentType } from '@thg-commerce/enterprise-components/ProductVariations/src/ProductVariations'
import { PurchaseOptions } from '@thg-commerce/enterprise-components/PurchaseOptions'
import { SubscriptionChoice } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Product'
import { SubscriptionContract } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { TransformedOption } from '@thg-commerce/enterprise-product-options/src/transformers/option'
import {
  Price,
  PriceProps,
  ProductImageUrls,
  QuantitySelector,
} from '@thg-commerce/gravity-elements'
import { QuantitySelectorProps } from '@thg-commerce/gravity-elements/QuantitySelector/QuantitySelector'
import {
  QuickBuyComponent,
  QuickBuyModalThemeInterface,
  SelectedOptions,
  Tags,
} from '@thg-commerce/gravity-patterns'
import { SimpleProductOptions } from '@thg-commerce/gravity-patterns/ProductOptions/SimpleProductOptions'
import { useBreakpoint } from '@thg-commerce/gravity-system'
import { BreakpointType, spacing } from '@thg-commerce/gravity-theme'

import {
  AddToBasketButton,
  AvailabilityMessage,
  ContentContainer,
  ModalContentWrapper,
  ModalFooterContainer,
  ModalTitle,
  PricingSection,
  ProductTagsContainer,
  ProductTitle,
  QuantityPlatformMessage,
  QuantityText,
  StyledErrorMessage,
  StyledErrorWrapper,
  StyledPageMessage,
  StyledProductImage,
  SubscriptionInfoMessage,
  ViewInfoButton,
} from './styles'

export interface QuickBuyModalContentProps {
  i18nText: {
    modalTitle: string
    rrpLabel: string
    saveLabel: string
    addToBasket: string
    viewMoreInformation: string
    missingProductMessage: string
    maxQuantityBasketItemMessageText: string
    availabilityMessage?: string
    failedToAddToBasketError?: string
    subscriptionInfoMessageText: string
    subscriptionContractDiscountMessage: string | null
    swatch: {
      unavailableText: string
      closeButtonText: string
      showLessButtonText: string
      showMoreButtonText: string
    }
    imageSwatch: {
      showButtonText: string
      showMoreButtonText: string
      showLessButtonText: string
    }
    dropdown: {
      unavailableText: string
    }
    wishlist: {
      unavailableText: string
    }
  }
  options: TransformedOption[]
  enablePreselectedProductVariant?: boolean
  selectedOptions?: { [optionKey: string]: string }
  setSelectedOptionsCallback: (optionKey: string, value: string) => void
  product?: {
    sku: string
    title: string
    images: ProductImageUrls[]
    tags?: string[]
    inStock: boolean
    enableAddToBasket?: boolean
    subscriptionData?: ProductSubscriptionData
    notifyWhenInStockEnabled?: boolean
  }
  pricing?: PriceProps['pricing']
  links?: {
    productUrl: string
  }
  callbacks: {
    quantityChanged?: (quantity: number) => void
    addedToBasketClicked: (
      selected: { [option: string]: string },
      quantity: number,
    ) => void
    viewMoreInformationClicked?: (
      event: React.MouseEvent<Element, MouseEvent>,
    ) => void
    addToBasketWithContractsCallback?: (
      quantity: number,
      contractId: string,
    ) => void
  }
  quantitySelector: Omit<
    QuantitySelectorProps,
    'editable' | 'quantityChangedCallback'
  >
  hideQuantitySelector?: boolean
  quickBuyModalTheme: QuickBuyModalThemeInterface
  coreTheme?: any
  setOpen?: (state: boolean) => void
  inStockComponent?: React.ReactElement
  displayViewMoreInfo?: boolean
  productSku?: string
}

interface ProductSubscriptionData {
  productInBasketWithSubscriptionContract: boolean
  productInBasketWithoutSubscriptionContract: boolean
  selectedFrequencyId: string
  enableSubscriptionContracts?: boolean
  activeTabIndex: number
  subscriptionPurchaseOptions?: {
    setActiveTabIndex: (index: number) => void
    activeTabIndex: number
    selectedFrequencyId: string
    setSelectedFrequencyId: (id: string) => void
    showSavingsAmountOnSubscriptionCard: boolean
    selectedOption?: SelectedOptions
    onOptionChange?: (key: string, value: string) => void
    isSubscription: boolean
    subscribeOptions?: {
      upfront: SubscriptionChoice[]
      onDispatch: SubscriptionChoice[]
    }
    subscriptionPaymentType?: SubscriptionPaymentType
    subscriptionContracts: SubscriptionContract[]
    isQuickBuy: boolean
    purchaseOptionsStyle: any
  }
  displaySubscriptionChoicesOnly?: boolean
  displaySubscription: boolean
  isBasketEmpty?: boolean
  productSku?: string
}

interface QuickBuyComponentsProps {
  quickBuyModalContentProps: QuickBuyModalContentProps
  components: BreakpointType<(QuickBuyComponent | undefined)[]>
  selectedQuantity: number
  setSelectedQuantity: React.Dispatch<React.SetStateAction<number>>
}

const INITIAL_QUANTITY = 1

const allowAddToBasketTransformer = (props: {
  selectedQuantity: number
  selectedOptions?: { [optionKey: string]: string }
  enablePreselectedProductVariant?: boolean
  product?: {
    inStock: boolean
    enableAddToBasket?: boolean
  }
  options?: TransformedOption[]
}) =>
  !props.enablePreselectedProductVariant && !props.product?.enableAddToBasket
    ? true
    : props.selectedOptions &&
      Object.keys(props.selectedOptions)?.length === props.options?.length &&
      props.selectedQuantity &&
      props.product?.inStock

const slotComponentMap: {
  [component in QuickBuyComponent]: (
    {
      i18nText,
      product,
      pricing,
      links,
      quantitySelector,
      hideQuantitySelector,
      callbacks,
      quickBuyModalTheme,
      coreTheme,
      inStockComponent,
      options,
      selectedOptions,
      setSelectedOptionsCallback,
      enablePreselectedProductVariant,
    }: QuickBuyModalContentProps,
    selectedQuantity: number,
    setSelectedQuantity: React.Dispatch<React.SetStateAction<number>>,
  ) => React.ReactNode
} = {
  title: ({ product }: QuickBuyModalContentProps) => {
    if (!product) {
      return null
    }

    return (
      <div style={{ gridArea: QuickBuyComponent.TITLE }}>
        <ProductTitle reducedBottomMargin={!!(product.tags?.length || 0 > 0)}>
          {product.title}
        </ProductTitle>
        {product.tags && (product.tags?.length || 0) > 0 && (
          <ProductTagsContainer>
            <Tags tags={product.tags} />
          </ProductTagsContainer>
        )}
      </div>
    )
  },
  options: ({
    product,
    quickBuyModalTheme,
    inStockComponent,
    selectedOptions,
    options,
    i18nText,
    setSelectedOptionsCallback,
  }: QuickBuyModalContentProps) => {
    return (
      <div style={{ gridArea: QuickBuyComponent.OPTIONS }}>
        {product?.subscriptionData?.displaySubscription && (
          <PurchaseOptions
            {...product.subscriptionData.subscriptionPurchaseOptions}
            selectedOptions={selectedOptions}
            onOptionChange={setSelectedOptionsCallback}
          />
        )}
        {!product?.subscriptionData?.displaySubscriptionChoicesOnly && (
          <SimpleProductOptions
            options={options}
            selectedOptions={selectedOptions}
            onOptionChange={setSelectedOptionsCallback}
            i18nText={i18nText}
            order={quickBuyModalTheme.productOptions?.order}
            dropdownWidth={quickBuyModalTheme.productOptions?.dropdown?.width}
            showOnlyTwoRows={quickBuyModalTheme.swatch.showOnlyTwoRows}
            displayColoursAsTextOnly={
              quickBuyModalTheme.swatch.displayColoursAsTextOnly
            }
          />
        )}
        {i18nText.failedToAddToBasketError && (
          <StyledErrorWrapper>
            <StyledErrorMessage
              error={i18nText.failedToAddToBasketError}
              id={`quick-buy-add-to-basket-error-${product?.sku}`}
            />
          </StyledErrorWrapper>
        )}
        {inStockComponent && inStockComponent}
      </div>
    )
  },
  pricing: (
    props: QuickBuyModalContentProps,
    selectedQuantity: number,
    setSelectedQuantity: React.Dispatch<React.SetStateAction<number>>,
  ) => {
    return (
      <div style={{ gridArea: QuickBuyComponent.PRICING }}>
        <PricingSection
          flexDirection={props.quickBuyModalTheme.pricing.flexDirection}
          alignItems={props.quickBuyModalTheme.pricing.alignItems}
        >
          {props.pricing && (
            <Price
              pricing={props.pricing}
              priceDirection={props.quickBuyModalTheme.priceDirection}
              savings={props.quickBuyModalTheme.pricing.savings}
              i18nText={{
                saveLabel: props.i18nText.saveLabel,
                rrpLabel: props.i18nText.rrpLabel,
              }}
              priceTheme={props.quickBuyModalTheme.pricing.productPrice}
              discountMessageProps={{
                text: props.i18nText.subscriptionContractDiscountMessage,
                textStyle:
                  props.quickBuyModalTheme.pricing?.discountMessage?.textStyle,
              }}
            />
          )}
          {!props.hideQuantitySelector && (
            <div style={{ marginBottom: `${spacing(2)}` }}>
              <QuantityText>
                {props.quantitySelector.i18nText.quantityLabel}
              </QuantityText>
              <QuantitySelector
                {...props.quantitySelector}
                quantityChangedCallback={(quantity) => {
                  if (quantity) {
                    setSelectedQuantity(quantity)
                  }
                }}
                editable={true}
                quantity={selectedQuantity}
              />
            </div>
          )}
        </PricingSection>
      </div>
    )
  },
  addToBag: (props: QuickBuyModalContentProps, selectedQuantity: number) => {
    const allowAddToBasket = allowAddToBasketTransformer({
      selectedQuantity,
      enablePreselectedProductVariant: props.enablePreselectedProductVariant,
      product: props.product,
      options: props.options,
      selectedOptions: props.selectedOptions,
    })

    const allowAddToBasketAsSubscription =
      props.product?.subscriptionData?.enableSubscriptionContracts &&
      props.product.subscriptionData.subscriptionPurchaseOptions
        ?.activeTabIndex === 1 &&
      !!props.product.subscriptionData.subscriptionPurchaseOptions
        .subscriptionContracts.length

    const disableAddToBasketSubscriptions =
      props.product?.subscriptionData?.enableSubscriptionContracts &&
      (props.product?.subscriptionData
        ?.productInBasketWithSubscriptionContract ||
        (props.product?.subscriptionData
          ?.productInBasketWithoutSubscriptionContract &&
          !!props.product.subscriptionData.subscriptionPurchaseOptions
            ?.subscriptionContracts.length))

    return (
      <AddToBagContainer
        allowAddToBasket={!!allowAddToBasket}
        allowAddToBasketAsSubscription={!!allowAddToBasketAsSubscription}
        disableAddToBasketSubscriptions={!!disableAddToBasketSubscriptions}
        selectedQuantity={selectedQuantity}
        {...props}
      />
    )
  },
  availability: (props: QuickBuyModalContentProps) => {
    return (
      <div style={{ gridArea: QuickBuyComponent.AVAILABILITY }}>
        {props.i18nText.availabilityMessage && (
          <AvailabilityMessage>
            {props.i18nText.availabilityMessage}
          </AvailabilityMessage>
        )}
      </div>
    )
  },
  viewProduct: (props: QuickBuyModalContentProps) => {
    return (
      <div style={{ gridArea: QuickBuyComponent.VIEWPRODUCT }}>
        {props.links && (
          <ViewInfoButton
            emphasis="medium"
            href={props.links.productUrl}
            renderedAs="a"
            onClick={() => {
              props.callbacks?.viewMoreInformationClicked &&
                props.callbacks.viewMoreInformationClicked
              props.setOpen && props.setOpen(false)
            }}
          >
            {props.i18nText.viewMoreInformation}
          </ViewInfoButton>
        )}
      </div>
    )
  },
}

export const AddToBagContainer = (
  props: Pick<
    QuickBuyModalContentProps,
    | 'selectedOptions'
    | 'callbacks'
    | 'i18nText'
    | 'product'
    | 'quantitySelector'
  > & {
    allowAddToBasket: boolean
    allowAddToBasketAsSubscription: boolean
    disableAddToBasketSubscriptions: boolean
    selectedQuantity: number
  },
) => {
  return (
    <div style={{ gridArea: QuickBuyComponent.ADDTOBAG }}>
      <AddToBasketButton
        emphasis="high"
        disabled={
          !props.allowAddToBasket ||
          props.quantitySelector.maxValue < 1 ||
          props.disableAddToBasketSubscriptions
        }
        onClick={() => {
          if (
            props.allowAddToBasket &&
            props.selectedQuantity &&
            props.selectedOptions
          ) {
            if (props.allowAddToBasketAsSubscription) {
              props.callbacks.addToBasketWithContractsCallback &&
                props.callbacks.addToBasketWithContractsCallback(
                  props.selectedQuantity,
                  props.product?.subscriptionData?.subscriptionPurchaseOptions
                    ?.selectedFrequencyId!,
                )
            } else {
              props.callbacks.addedToBasketClicked(
                props.selectedOptions,
                props.selectedQuantity,
              )
            }
          }
        }}
      >
        {props.i18nText.addToBasket}
      </AddToBasketButton>
    </div>
  )
}

const QuickBuyComponents = (props: QuickBuyComponentsProps) => {
  const breakpoint = useBreakpoint()

  return (
    <ContentContainer>
      {props.components[breakpoint].map(
        (component: QuickBuyComponent | undefined) => {
          if (!component) {
            return null
          }

          return slotComponentMap[component](
            props.quickBuyModalContentProps,
            props.selectedQuantity,
            props.setSelectedQuantity,
          )
        },
      )}
    </ContentContainer>
  )
}

const ModalTopContent = (props: {
  i18nText: {
    subscriptionInfoMessageText: string
    maxQuantityBasketItemMessageText: string
    failedToAddToBasketError?: string
  }
  productSku: string
  quantitySelectorMaxValue: number
  subscriptionData?: ProductSubscriptionData
}) => {
  const hasSubscriptionInfoText =
    props.subscriptionData?.productInBasketWithSubscriptionContract ||
    (props.subscriptionData?.productInBasketWithoutSubscriptionContract &&
      !!props.subscriptionData?.subscriptionPurchaseOptions
        ?.subscriptionContracts.length)

  return (
    <React.Fragment>
      {hasSubscriptionInfoText && (
        <SubscriptionInfoMessage
          text={props.i18nText.subscriptionInfoMessageText}
          type="info"
        />
      )}
      {!props.quantitySelectorMaxValue && (
        <QuantityPlatformMessage
          type="info"
          text={props.i18nText.maxQuantityBasketItemMessageText}
          data-testid="quantity-basket-item"
        />
      )}
    </React.Fragment>
  )
}

const ModalBottomContent = (
  props: Pick<
    QuickBuyModalContentProps,
    'i18nText' | 'selectedOptions' | 'callbacks' | 'links' | 'setOpen'
  > & {
    modalButtonsRef: React.RefObject<HTMLDivElement>
    modalContentScrollable: boolean
    allowAddToBasket: boolean
    selectedQuantity: number
    displayViewMoreInfo?: boolean
  },
) => (
  <ModalFooterContainer
    ref={props.modalButtonsRef}
    modalContentScrollable={props.modalContentScrollable}
  >
    <AddToBasketButton
      emphasis="high"
      disabled={!props.allowAddToBasket}
      onClick={() => {
        if (
          props.allowAddToBasket &&
          props.selectedQuantity &&
          props.selectedOptions
        ) {
          props.callbacks.addedToBasketClicked(
            props.selectedOptions,
            props.selectedQuantity,
          )
        }
      }}
    >
      {props.i18nText.addToBasket}
    </AddToBasketButton>
    {props.links && !props.displayViewMoreInfo && (
      <ViewInfoButton
        emphasis="medium"
        href={props.links.productUrl}
        renderedAs="a"
        onClick={() => {
          props.callbacks?.viewMoreInformationClicked &&
            props.callbacks.viewMoreInformationClicked
          props.setOpen && props.setOpen(false)
        }}
      >
        {props.i18nText.viewMoreInformation}
      </ViewInfoButton>
    )}
  </ModalFooterContainer>
)

export const QuickBuyModalContent = ({
  i18nText,
  product,
  options,
  pricing,
  links,
  quantitySelector,
  hideQuantitySelector,
  callbacks,
  quickBuyModalTheme,
  coreTheme,
  setOpen,
  inStockComponent,
  selectedOptions,
  setSelectedOptionsCallback,
  enablePreselectedProductVariant,
  displayViewMoreInfo,
}: QuickBuyModalContentProps) => {
  const [selectedQuantity, setSelectedQuantity] = React.useState<number>(
    INITIAL_QUANTITY,
  )
  const [modalContentScrollable, setModalContentScrollable] = React.useState<
    boolean
  >(false)
  const modalContentRef = React.useRef<HTMLDivElement>(null)
  const modalButtonsRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (!modalContentRef?.current || !modalButtonsRef?.current) {
      return
    }

    let resizeObserver: ResizeObserver | undefined
    resizeObserver = new ResizeObserver(() => {
      const modalContentRect = modalContentRef?.current?.getBoundingClientRect()

      const modalButtonsRect = modalButtonsRef?.current?.getBoundingClientRect()

      if (modalContentRect && modalButtonsRect) {
        setModalContentScrollable(
          modalContentRect.top + modalContentRect.height > modalButtonsRect.top,
        )
      }
    })
    resizeObserver.observe(modalContentRef.current)

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [modalContentRef])

  if (!product) {
    return (
      <StyledPageMessage
        flashMessageId="quickBuyModalMissingProduct"
        message={i18nText.missingProductMessage}
        type="error"
      />
    )
  }

  const allowAddToBasket = allowAddToBasketTransformer({
    enablePreselectedProductVariant,
    product,
    selectedQuantity,
    options,
    selectedOptions,
  })

  return (
    <React.Fragment>
      <ModalTitle>{i18nText.modalTitle}</ModalTitle>
      <ModalTopContent
        i18nText={{
          subscriptionInfoMessageText: i18nText.subscriptionInfoMessageText,
          maxQuantityBasketItemMessageText:
            i18nText.maxQuantityBasketItemMessageText,
          failedToAddToBasketError: i18nText.failedToAddToBasketError,
        }}
        productSku={product.sku}
        subscriptionData={product.subscriptionData}
        quantitySelectorMaxValue={quantitySelector.maxValue}
      />
      <ModalContentWrapper
        ref={modalContentRef}
        reducedBottomPadding={quickBuyModalTheme.enableStickyFooterButtons}
      >
        <StyledProductImage
          urls={product.images[0]}
          alt={product.title}
          width={
            quickBuyModalTheme.product?.image?.width || [
              '100%',
              '100%',
              190,
              191,
            ]
          }
          height={
            quickBuyModalTheme.product?.image?.height || ['', '', 190, 191]
          }
        />
        <QuickBuyComponents
          quickBuyModalContentProps={{
            i18nText,
            product,
            pricing,
            links,
            quantitySelector,
            hideQuantitySelector,
            callbacks,
            quickBuyModalTheme,
            coreTheme,
            inStockComponent,
            options,
            selectedOptions,
            setSelectedOptionsCallback,
            setOpen,
            enablePreselectedProductVariant,
            productSku: product?.sku.toString(),
          }}
          components={quickBuyModalTheme.enabledComponents}
          selectedQuantity={selectedQuantity}
          setSelectedQuantity={setSelectedQuantity}
        />
      </ModalContentWrapper>
      {quickBuyModalTheme.enableStickyFooterButtons && (
        <ModalBottomContent
          i18nText={i18nText}
          modalButtonsRef={modalButtonsRef}
          modalContentScrollable={modalContentScrollable}
          allowAddToBasket={!!allowAddToBasket}
          selectedQuantity={selectedQuantity}
          selectedOptions={selectedOptions}
          callbacks={callbacks}
          links={links}
          displayViewMoreInfo={displayViewMoreInfo}
        />
      )}
    </React.Fragment>
  )
}
